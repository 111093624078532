import  {defineStore, storeToRefs} from 'pinia';
import axiosAuth from '@/utils/AxiosAuth';
import {ref} from 'vue';
import {useRuntimeStore} from './runtimeStore';
import {notify} from 'notiwind';
import {useCookie} from '@/@core/composable/useCookie'
import {router} from "@/plugins/1.router/index.js";


export const useNotification = defineStore('NotificationStore', () => {
    const root = useRuntimeStore().$state.apiRoot
    const NotificationList = ref([]);
    const unseen_count = ref(0)
    const isLoading = ref(true)
    const showNotification = ref(false)


    const getNotificationList = async () => {
        try {
            const res = await axiosAuth
                .get(`${root}/notifications/list/${useCookie('userData')?.value.id}`)
                .then((res) => {
                    isLoading.value = false
                    NotificationList.value = res.data.notifications;
                    unseen_count.value = res.data.unseen_count
                    showNotification.value = true
                })
        } catch (error) {
            isLoading.value = false
            console.error(error);
            notify(
                {
                    group: "error",
                    title: 'Error',
                    text: error?.response?.data?.message,
                },
                2000
            );
        }
    };

    const updateNotification = async (notificationId) => {
        await axiosAuth
            .put(`${root}/notifications/${notificationId}`)
            .then((res) => {
                console.log(res)
                // unseen_count.value -= 1
            })
            .catch((err) => {
                if (err.response && err.response.status == 500) {
                    console.log(err.response)
                }
            })
    }

    const value = {
        NotificationList,
        unseen_count,
        isLoading,
        showNotification
    };

    const actions = {
        getNotificationList,
        updateNotification
    };

    return {
        ...value,
        ...actions,
    };
});