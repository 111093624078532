<script setup>
import {useAuth} from '@/stores/authStore'
import { storeToRefs } from 'pinia'
import { themeConfig } from '@themeConfig'
import bgLogin from '@/assets/images/login_background.jpg'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import loader from "@/pages/loader.vue"
import {notify} from "notiwind";
import {useGeoJson} from "@/stores/geoJsonStore.js";
import {useCookie} from "@core/composable/useCookie.js";
import {initIntercom} from "@/utils/intercomHelper.js";
const {  getUserPosition} = useGeoJson()

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})
const {userLogin,getUsers} = useAuth()
const { isLoading, invalidCredentials } = storeToRefs(useAuth())
const isPasswordVisible = ref(false)
const route = useRoute()
const router = useRouter()
const ability = useAbility()

const errors = ref({
  email: undefined,
  password: undefined,
})

const refVForm = ref()
  await getUserPosition()

const credentials = ref({
  email: '',
  password: '',
})

const rememberMe = ref(false)



const login = async () => {
  try{
    await userLogin({
    username: credentials.value.email,
    password: credentials.value.password
  });
    await nextTick(() => {
    const userAbilityRules = useCookie('userAbilityRules').value;
    ability.update(userAbilityRules)
    if (ability.can('manage','admin-dashboard') || ability.can('manage','crew-dispatch')){
    initIntercom()

    }
    router.replace(route.query.to ? String(route.query.to) : '/');
  });
    const roles =  useCookie('userData').value.roles
    const isAdmin =roles.includes('admin')
    if(isAdmin){
       notify(
            {
                group: "success",
                title: 'Login',
                text: 'Logged in successfully as admin',
            },
            2000
        );
    }


  }catch (error) {
      if(!invalidCredentials){
        notify(
                {
                  group: "error",
                  title: 'Error',
                  text: `Something went wrong while logging in`,
                },
                2000
            );
      }
    }

}




const userValidate = async (value) => {
  const users = await getUsers(value)
  if (users && users[0][1] !== null) {
    return true;
  } else {
    return 'Username does not exist';
  }
}

const validateUsername = async () => {
    await userValidate(credentials.value.email).then(result => {
      if (typeof result === 'string') {
        errors.value.email = result;
      } else {
        errors.value.email = undefined;
      }
    });

}
const onSubmit =async () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid){
      login()
    }
  })
}
</script>

<template>
  <section
      class="relative flex items-center justify-center min-h-screen bg-cover"
      :style="{ backgroundImage: `url(${bgLogin})`, backgroundSize: `cover` }"
  >
    <div class="auth-wrapper d-flex align-center justify-center pa-4">
      <v-col cols="12" sm="7" md="6" lg="4" xl="3">
        <VCard
          class="auth-card pa-sm-4 pa-md-7 pa-0"
      >
          <loader v-if="isLoading" />
        <VCardText>
          <div class="d-flex align-center gap-x-3 justify-center mb-6">
            <VNodeRenderer :nodes="themeConfig.app.loginLogo" />

            <h1 class="auth-title">
              {{ themeConfig.app.title }}
            </h1>
          </div>
<!--          <h4 class="text-h4 mb-1">-->
<!--            Welcome to <span class="text-capitalize">{{ themeConfig.app.title }}!</span>-->
<!--          </h4>-->
<!--          <p class="mb-0">-->
<!--            Please sign-in to your account and start the adventure-->
<!--          </p>-->
        </VCardText>

        <VCardText>
          <VForm
              ref="refVForm"
              @submit.prevent="onSubmit"
          >
            <VRow>
              <p class=" d-flex text-body-1 pl-3">Please sign-in to your account.</p>
              <!-- email -->
              <VCol cols="12">
                <VTextField
                    v-model="credentials.email"
                    label="Username"
                    placeholder="johndoe"
                    autofocus
                    :rules="[usernameValidator,requiredValidator]"
                    :error-messages="errors.email"
                    @blur="validateUsername"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VTextField
                    v-model="credentials.password"
                    label="Password"
                    placeholder="············"
                    :rules="[requiredValidator]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :error-messages="errors.password"
                    :append-inner-icon="isPasswordVisible ? 'ri-eye-off-line' : 'ri-eye-line'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div class="d-flex align-center flex-wrap justify-space-between my-5 gap-4">
                  <!--                <VCheckbox-->
                  <!--                  v-model="form.remember"-->
                  <!--                  label="Remember me"-->
                  <!--                />-->
                  <RouterLink
                      class="text-primary"
                      :to="{ name: 'forgot-password' }"
                  >
                    Forgot Password?
                  </RouterLink>
                </div>

                <VBtn
                    block
                    type="submit"
                >
                  Login
                </VBtn>
              </VCol>
              <!-- auth providers -->
              <!--            <VCol-->
              <!--              cols="12"-->
              <!--              class="text-center"-->
              <!--            >-->
              <!--              <AuthProvider />-->
              <!--            </VCol>-->
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
      </v-col>
    </div>
  </section>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
