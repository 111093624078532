// import router from '@/router'
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useAttach } from './attachmentStore'
import { storeToRefs } from 'pinia'
import { notify } from 'notiwind'
import {router} from '@/plugins/1.router'
import { useAdmin } from '@/stores/adminStore'
import { accessToken } from 'mapbox-gl'

export const useJobStore = defineStore('jobStore', () => {
  const isNew = ref(false)
  const isLoading = ref(false)
  const isEditing = ref(false)
  const isCreating = ref(false)
  const projects = ref([])
  const selectedJob = ref(null)
  const projectDispatches = ref([])
  const disableButton = ref(false)

  const editedJob = ref(null)
  // const showJobModal = ref(useToggle(false))
  const currentEstimate = ref(null)
  const currentJob = ref(null)

  const lightboxImage = ref(null)
  const showLightbox = ref(useToggle(false))
  const previousSelectedJob = ref(null)
  const isDisabledItem = ref(false)
  const visitScheduledStatus = ref()

  const root = useRuntimeStore().$state.apiRoot

  const { adminSettings } = storeToRefs(useAdmin())

  // actions
  const toggleEditing = () => {
    isEditing.value = !isEditing.value
  }

  const setNewJob = () => {
    isNew.value = true
    isEditing.value = true
    editedJob.value = {
      client: { id: null, name: null },
      service_address: { id: null },
      arborist: { id: null }
    }
  }

  const setSelectedJob = (project) => {
    selectedJob.value = project
  }
  const jobLost = (project) => {
    const body = {
    secondary_status : "lost"
    }
    axiosAuth
        .put(`${root}/projects/${project.id}`, body)
        .then((res) => {
          console.log("-0-----res", res)
          selectedJob.value = res.data

          notify({
            group: 'success',
            title: 'Job Lost',
            text: 'Your job marked as lost successfully.'
          })
  }
)
}

  const createJob = async (project,type) => {
    project.value.start_date = new Date().toISOString()
    axiosAuth
      .post(`${root}/projects/`, project.value)
      .then((res) => {
         selectedJob.value = res.data
          if (!Array.isArray(projects.value)) {
              console.error('projects.value is not an array');
              projects.value = [];
            }
        projects.value.push(res.data)
        router.push({ name: 'jobs-view-id', params: { id: res.data.id } , query:{origin: type}})
      })
        .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)
        projects.value = []
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const editJob = async (project) => {
    isNew.value = false
    isEditing.value = true
    let open = new Date(project.open_date).toISOString()
    let end = new Date(project.end_date).toISOString()
    const body = {
      open_date: open,
      end_date: end
    }
    await axiosAuth
      .put(`${root}/projects/${selectedJob.value.id}`, body)
      .then((res) => {
        selectedJob.value = res.data

        notify({
          group: 'Success',
          title: 'Success',
          text: 'The estimate has been signed.'
        })
      })
      .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)

        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const getJobs = async (params , queryString) => {
    const queryParams = params  && selectedJob.value ? new URLSearchParams(params) : null
    let url = `${root}/projects/`
    if (queryString  && selectedJob.value) {
      url = `${root}/projects/${queryString}`
    }
    await axiosAuth
     .get(`${url}`, { params: queryParams })
      .then((res) => {
        projects.value = res.data.projects
      })
      .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)
        projects.value = []
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const getClientJobs = async (params , queryString) => {
    const queryParams = params ? new URLSearchParams(params) : null
    let url = `${root}/projects/`
    if (queryString) {
      url = `${root}/projects/${queryString}`
    }
    await axiosAuth
        .get(`${url}`, { params: queryParams })
        .then((res) => {
          projects.value = res.data
        })
        .catch((err) => {
          // projects array needs to be cleared to ensure that we're not showing
          // projects from a previous context (client, service address, etc)
          projects.value = []
          if (err.response && err.response.status == 500) {
            console.log(err.response)
            notify({
              group: 'error',
              title: 'Error Loading Jobs',
              text: 'There was a problem loading projects.'
            })
          }
        })
  }

  const getJobById = async (projectId) => {
    selectedJob.value = null
    await axiosAuth
      .get(`${root}/projects/${projectId}/`)
      .then((res) => {
        currentJob.value = selectedJob.value
        selectedJob.value = res.data
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }
  
  const getJobByAccessToken = async (accessToken) => {
    selectedJob.value = null
    await axiosAuth
      .get(`${root}/projects/customer/${accessToken}`)
      .then((res) => {
        selectedJob.value = res.data
        currentEstimate.value = res.data.estimates[res.data.estimates.length -1]
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  let previewEstimate = ref(null)

  const setPreviewEstimate = (estimate) =>{
    previewEstimate.value=estimate
  }

  const setCurrentEstimate = (estimate) => {
    if (!estimate?.id) {
      isCreating.value = true
    }
    currentEstimate.value = estimate
    }
    // let previewEstimate = ref(null)

  const createEstimate = async (projectId, estimate, hideMessage) => {
  console.log("createEstimate")
    await axiosAuth
      .post(`${root}/projects/${projectId}/estimate`, estimate)
      .then((res) => {
        let estimateIndex = selectedJob.value.estimates.findIndex(
          (item) => item.id === estimate.id
        )
        currentEstimate.value = res.data
        selectedJob.value.estimates[estimateIndex] = res.data
         setPreviewEstimate(null)
        if (!hideMessage) {
          notify({
            group: 'success',
            title: 'Estimate Saved',
            text: 'Your estimate was saved successfully.'
          })
        }
      })
      .catch((err) => {
      console.log(err,"apoerror")
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error',
            text: 'There was a problem saving this estimate.'
          })
        }
      })
  }

  const saveEstimate = async (projectId, estimate, hideMessage, agreementSave) => {
    await axiosAuth
      .post(`${root}/projects/${projectId}/update-estimate`, estimate)
      .then((res) => {
        // TODO: address state issues caused by mismatches with response data and local state
        // currently this works just leaving state as-is if we know it's saved
        // setCurrentEstimate(res.data)
        if(!agreementSave){
          let estimateIndex = selectedJob.value.estimates.findIndex(
              (item) => item.id === estimate.id
          )
          currentEstimate.value = res.data
          selectedJob.value.estimates[estimateIndex] = res.data
        }
        setPreviewEstimate(null)
        if (!hideMessage) {
          notify({
            group: 'success',
            title: 'Estimate Saved',
            text: 'Your estimate was saved successfully.'
          })
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error',
            text: 'There was a problem saving this estimate.'
          })
        }
      })
  }
  
  const saveEstimateWithToken = async (projectId, estimate, hideMessage,access_token) => {
    await axiosAuth
      .post(`${root}/projects/${projectId}/customer/update-estimate/${access_token}`, estimate)
      .then((res) => {
        // TODO: address state issues caused by mismatches with response data and local state
        // currently this works just leaving state as-is if we know it's saved
        // setCurrentEstimate(res.data)
        let estimateIndex = selectedJob.value.estimates.findIndex(
            (item) => item.id === estimate.id
        )
        currentEstimate.value = res.data
        selectedJob.value.estimates[estimateIndex] = res.data
        setPreviewEstimate(null)
        if (!hideMessage) {
          notify({
            group: 'success',
            title: 'Estimate Saved',
            text: 'Your estimate was saved successfully.'
          })
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error',
            text: 'There was a problem saving this estimate.'
          })
        }
      })
  }

  const scheduleVisit = async (visit_id) => {
    await axiosAuth
        .get(`${root}/recurring_services/agreements/scheduler/${visit_id}`)
        .then((res) => {
          visitScheduledStatus.value = 'scheduled'
          notify({
            group: 'success',
            title: 'Estimate Saved',
            text: 'Your visit estimate is created in the waiting list.'
          })
        })
        .catch((err) => {
          if (err.response && err.response.status == 500) {
            console.log(err.response)
            notify({
              group: 'error',
              title: 'Error Loading Jobs',
              text: 'There was a problem loading projects.'
            })
          }
        })
  }

  const signEstimate = async (projectId, estimateId) => {
    const { signatureImage } = storeToRefs(useAttach())
    const body = {
      attachment_id: signatureImage.value.id
    }
    await saveEstimate(projectId, currentEstimate.value, true)

    await axiosAuth
      .post(`${root}/projects/${projectId}/estimate/${estimateId}/`, body)
      .then(async (res) => {
        await getJobById(selectedJob.value.id)
        notify({
          group: 'Success',
          title: 'Success',
          text: 'The estimate has been signed.'
        })
      })
      .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)

        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const signEstimateToken = async (project_id,estimate_id,accessToken) => {
    const { signatureImage } = storeToRefs(useAttach())
    const body = {
      attachment_id: signatureImage.value.id
    }
    await axiosAuth
      .post(`${root}/projects/${project_id}/estimate/${estimate_id}/customer/${accessToken}`, body)
      .then(async (res) => {
        currentEstimate.value = null
        await getJobByAccessToken(accessToken)
        notify({
          group: 'Success',
          title: 'Success',
          text: 'The estimate has been signed.'
        })
      })
      .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)

        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const generateToken = async (project_id) => {
    await axiosAuth
      .get(`${root}/projects/${project_id}/generate_token`)
      .then(async (res) => {
        window.open(router.resolve({path: '/customer-portal',query: { access_token: res.data }}).href)
      })
      .catch((err) => {
        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Opening Portal',
            text: 'There was a problem in Opening portal.'
          })
        }
      })
  }

  const createChangeOrder = async (projectId, estimateId) => {
    await axiosAuth
      .post(
        `${root}/projects/${projectId}/estimate/${estimateId}/create-change-order`
      )
      .then((res) => {
        setCurrentEstimate(res.data)
        selectedJob.value.estimates.push(currentEstimate.value)

        notify({
          group: 'Success',
          title: 'Success',
          text: 'The estimate has been signed.'
        })
      })
      .catch((err) => {
        // projects array needs to be cleared to ensure that we're not showing
        // projects from a previous context (client, service address, etc)

        if (err.response && err.response.status == 500) {
          console.log(err.response)
          notify({
            group: 'error',
            title: 'Error Loading Jobs',
            text: 'There was a problem loading projects.'
          })
        }
      })
  }

  const currentEstimateTotal = computed(() => {
    const productTotal = currentEstimate.value?.product_line_items?.reduce(
      (acc, item) => {
        // TODO: refactor product_total and total_price with APIs line_item_total
        const itemTotal = item.product_total
          ? item.product_total
          : item.line_item_total
        acc += itemTotal > 0 ? itemTotal : 0
        return acc
      },
      0
    )
    const serviceTotal = currentEstimate.value?.service_line_items?.reduce(
      (acc, item) => {
        const itemTotal = item.total_price
          ? item.total_price
          : item.line_item_total
        acc += itemTotal > 0 ? itemTotal : 0
        return acc
      },
      0
    )
    const combinedTotal = productTotal + serviceTotal
    return combinedTotal
  })

  const currentEstimateTaxTotal = computed(() => {
    const productTaxTotal = currentEstimate.value?.product_line_items?.reduce(
      (acc, item) => {
        if (item.sales_tax) {
          const itemTotal =
            (item.product_total ? item.product_total : item.line_item_total) *
            adminSettings.value.product_tax_rate
          acc += itemTotal > 0 ? itemTotal : 0
        }
        return acc
      },
      0
    )
    const serviceTaxTotal = currentEstimate.value?.service_line_items?.reduce(
      (acc, item) => {
        if (item.sales_tax) {
          const itemTotal =
            (item.total_price ? item.total_price : item.line_item_total) *
            adminSettings.value.service_tax_rate
          acc += itemTotal > 0 ? itemTotal : 0
        }
        return acc
      },
      0
    )
    const combinedTotal = productTaxTotal + serviceTaxTotal
    return combinedTotal
  })

  // flattened list of all current products and services for all dispatches
  // used to check whether new items are included in this list
  const currentDispatchItems = computed(() => {
    return selectedJob.value?.dispatches.reduce((acc, dispatch) => {
      dispatch.product_line_items.map((product) => {
        acc.push(product)
      })
      dispatch.service_line_items.map((service) => {
        acc.push(service)
      })
      return acc
    }, [])
  })

  const values = {
    isNew,
    disableButton,
    isLoading,
    currentJob,
    isEditing,
    isCreating,
    selectedJob,
    editedJob,
    projects,
    currentEstimate,
    // showJobModal,
    projectDispatches,
    lightboxImage,
    showLightbox,
    currentDispatchItems,
    currentEstimateTaxTotal,
    currentEstimateTotal,
    previewEstimate,
    previousSelectedJob,
    isDisabledItem,
    visitScheduledStatus
  }

  const actions = {
    setNewJob,
    setSelectedJob,
    toggleEditing,
    editJob,
    getJobs,
    getClientJobs,
    getJobById,
    createJob,
    setCurrentEstimate,
    createEstimate,
    saveEstimate,
    scheduleVisit,
    signEstimate,
    createChangeOrder,
    setPreviewEstimate,
    getJobByAccessToken,
    signEstimateToken,
    saveEstimateWithToken,
    jobLost,
    generateToken
  }

  return {
    ...values,
    ...actions
  }
})
